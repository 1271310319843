import * as React from "react"
import Layout from "./../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const ProjectsPage = () => (
  <Layout>
    <SEO title="Проекти" />
    <div className="container">
      <h1>Проекти</h1>
      {/* create 2 big bootstrap cards with two projects - Bella Gourmet(bellagourmet.eu) and Rahouse (rahouse.rest) */}
      <div className="row">
        <div className="col-md-6">
          <div className="card mb-4 h-100">
            <a href="https://bellagourmet.eu">
              <StaticImage src="../images/projects/bellagourmet.jpg" className="card-img-top" alt="Bellagourmet"/>
            </a>
            <div className="card-body">
              <h2 className="card-title">Bella Gourmet</h2>
              <p className="card-text fs-4">
                Вид услуга: <strong>Изработка на онлайн магазин</strong>
              </p>
              <a href="https://bellagourmet.eu" className="btn btn-primary text-white">Посети Сайта</a>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card mb-4 h-100">
            <a href="https://rahouse.surge.sh">
              <StaticImage src="../images/projects/rahouse.jpg" className="card-img-top" alt="Rahouse"/>
            </a>
            <div className="card-body">
              <h2 className="card-title">Rahouse</h2>
              <p className="card-text fs-4">
                Вид услуга: <strong>Изработка на уебсайт за заведения (ресторант)</strong>
              </p>
              <a href="https://rahouse.surge.sh/bg" className="btn btn-primary text-white">Посети Сайта</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProjectsPage
